<template>
  <el-dialog :visible.sync="visible" title="编辑产品" :close-on-click-modal="false">
    <el-form ref="form" :model="form" v-loading="formLoading" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="账号" prop="userName">
            <el-input v-model="form.userName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="用户名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="手机" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="密码" prop="pwd">
            <el-input v-model="form.pwd"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="公司名称" prop="companyName">
            <el-input v-model="form.companyName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="用户类型" prop="userType">
            <el-select v-model="form.userType" style="width:100%">
              <el-option value="Employee"></el-option>
              <el-option value="Customer"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="本地打印序列号" prop="localPrintSerialNumber">
            <el-input v-model="form.localPrintSerialNumber"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">

        </el-col>
      </el-row>

    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button type="primary" @click="updateModel">保存</el-button>
        <el-button @click="visible=false">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      formLoading: false,
      form: {},
      rules: {},
    };
  },
  methods: {
    edit(row) {
      let that = this;
      that.visible = true;
      that.formLoading = true;
      that.axios
        .post("Sys_UserInfo/GetModel", { onlyInt: row.id })
        .then(function (response) {
          that.form = response.data.data;
          that.formLoading = false;
        });
    },
    updateModel() {
      let that = this;
      that.formLoading = true;
      that.axios
        .post("Sys_UserInfo/UpdateModel", that.form)
        .then(function (response) {
          if (response.data.code == 101) {
            that.visible = false;
            that.formLoading = false;
            that.$parent.getList();
          }
        });
    },
  },
};
</script>

<style>
</style>