<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-input v-model="UserName">
          <el-select slot="prepend" v-model="searchCol" style="width:200px;">
            <el-option value="UserName" label="账号或者用户名称"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="getList(1)"></el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-table :data="this.tableData" v-loading="tableLoading" @row-dblclick="row=>$refs.editDialog.edit(row)">
          <el-table-column label="Id" prop="id"></el-table-column>
          <el-table-column label="账号" prop="userName" width="300"></el-table-column>
          <el-table-column label="用户名称" prop="name"></el-table-column>
          <el-table-column label="OpenId" prop="openId" width="300"></el-table-column>
          <el-table-column label="手机" prop="phone"></el-table-column>
          <el-table-column label="公司名称" prop="companyName"></el-table-column>
          <el-table-column label="用户类型" prop="userType"></el-table-column>
          <el-table-column label="注册时间" prop="insertTime"></el-table-column>
        </el-table>
        <el-pagination @current-change="getList" background :total="pm_pagination.total" :page-size="pm_pagination.pageSize" :current-page.sync="pm_pagination.currentPage"></el-pagination>
      </el-col>
    </el-row>
    <edit-dialog ref="editDialog"></edit-dialog>
  </div>
</template>
<script>
import EditDialog from "../UserInfo/EditDialog.vue";
export default {
  components: { EditDialog },
  data() {
    return {
      pm_pagination: {
        pageSize: 10,
        total: 0,
        currentPage: 1,
      },
      searchCol: "UserName",
      UserName: "",
      tableData: [],
      tableLoading: false,
    };
  },
  methods: {
    getList(cp) {
      var that = this;
      if (cp) that.pm_pagination.currentPage = cp;
      that.tableLoading = true;
      this.axios
        .post("Sys_UserInfo/GetList", {
          pm_pagination: that.pm_pagination,
          UserName: that.UserName,
        })
        .then(function (response) {
          if (response) {
            that.tableData = response.data.data.data;
            that.pm_pagination.total = response.data.data.total;
            that.tableLoading = false;
            console.log(response.data.data.total);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
  mounted: function () {
    this.getList();
  },
};
</script>
<style scoped>
</style>